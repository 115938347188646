// 全体の共通設定
html{
  font-size: 14px;
  font-family: "Montserrat" , "ヒラギノ角ゴ Pro W3" , "Hiragino Kaku Gothic Pro" , "Meiryo UI" , "メイリオ" , Meiryo , "ＭＳ Ｐゴシック" , "MS PGothic" , sans-serif;
  -webkit-font-smoothing: antialiased;
}

body{
  color: $textColor;
  -webkit-text-size-adjust: none;
  @include gtMaxWidth {
    overflow-x: hidden;
  };
  @include mobile-width {
    overflow-x: hidden;
  };
  word-break: break-all;
}

a{
  text-decoration: none;
}

img{
  vertical-align: bottom;
}

ul,li{
  margin: 0;
  list-style: none;
}

// リンクボタン
.btn-default{
  // background-color: #fff;
  // border: 1px solid $baseColor;
  border: 1px solid $textColor;
  box-sizing: border-box;
  // box-shadow: 0 8px 8px rgba(0,0,0,.2);
  // color: $baseColor;
  color: $textColor;
  display: inline-block;
  font-weight: 700;
  padding: 13px 40px 13px 23px;
  position: relative;
  transition: .2s;
  vertical-align: middle;
  &:hover {
    color: $baseColor;
    border: 1px solid $baseColor;
  }
  &.btn-white {
    color: #fff;
    border: 1px solid #fff;
  }
  &:after {
    content: '\f105';
    font-family: 'fontAwesome';
    font-size: 20px;
    vertical-align: middle;
    position: absolute;
    top: 50%;
    right: 10px;
    margin-top: -12px;
  }
}
// リクルートアイコン
.icon-recruit{
    position: relative;
    &:after{
        content: url(../img/common/icon_nav_recruit.svg);
        position: absolute;
        display: block;
        width: 20px;
        height: auto;
        top: 50%;
        transform: translateY(-45%);
        right: -22px;
        @include mobile-width {
            width: 20px;
            transform: translateY(-40%);
            right: -20px;
        };
    }
}
.icon-outside{
    position: relative;
    &:after{
        content: url(../img/common/icon_outside_white.svg);
        position: absolute;
        display: block;
        width: 20px;
        height: auto;
        top: 50%;
        transform: translateY(-45%);
        right: -22px;
        @include mobile-width {
            width: 20px;
            transform: translateY(-40%);
            right: -20px;
        };
    }
}


// 3分割のスタイル
.col3{
    max-width: $maxWidth;
  @include clearfix;
  @include ltMaxWidth {
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 20px;
  };
  @include mobile-width() {
    width: auto;
  };
  margin: 0 auto;

  &-item{
    width: 30%;
    float: left;

    &:not(:first-of-type){
      margin-left: 5%;
    }
  }
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

// コンテンツの高さを画面の高さに設定する
.vh {
  height: 100vh;
}

.minheight {
  .vh {
    height: auto;
  }
}

.bgGray{
  background-color: $bgGray;
  margin: auto;
  padding: 40px 0;
  @include ltMaxWidth {
    width: $maxWidth;
  };
  @include mobile-width {
    width: auto;
  };
}

.no-scroll {
  padding-botom: 72px;
}

.table {
  width: 100%;
  border-collapse: collapse;
  font-size: 18px;
  th, td {
    padding: 20px 0;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    text-align: left;
    vertical-align: top;
    font-weight: normal;
  }
  td {
    font-size: 16px;
      ul{
          li{
              margin-bottom: 10px;
              list-style: none;
          }
      }
  }
  @include mobile-width {
    tr:last-child td {
      border-bottom: 1px solid #ccc;
    }
    th, td {
      padding: 10px;
      font-size: 15px;
    }
    td {
      font-size: 13px;
      border-bottom: 0;
    }
    th {
      background: #f4f4f4;
      border-bottom: 0;
    }
    ul{
        li{
            font-size: 13px;
        }
      }
  };
}


.sp {
  display: none;
  @include mobile-width() {
    display: block;
  }
}
.pc {
  @include mobile-width() {
    display: none !important;
  }
}


.iframe-content {
    position: relative;
    width: 100%;
    padding: 75% 0 0 0;
}
.iframe-content iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
img.responsive {
  @include mobile-width {
    width: 100%;
    height: auto;
  };
}

p {
  text-align: justify;
}


#loading {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 1500;
  top: 0;
  left: 0;

  .spinner-double-dot-stick {
    position: absolute;
    top: 50%;
    margin-top: -125px;
    left: 50%;
    margin-left: -125px;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(../img/common/logo_kddi.png) no-repeat center;
    background-size: 134px auto;
  }
}

#loading-no-image {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 1500;
  top: 0;
  left: 0;
}
