.topContent--recruit{

  background-color: $bgGray;
  position: relative;
  z-index: 120;
  @include mobile-width() {
    padding: 0;
    .btn-default {
      display: block;
    }
  }

  &-wrap{
    @include mobile-width() {
      padding:40px 20px 0;
    };
    @include clearfix;
  }

  &-image {
      position: relative;
      @include mobile-width {
        margin-left: -20px;
        margin-right: -20px;
      };
  }

  &-des {
    width: 88%;
    @include mobile-width {
      width: auto;
    };
  }

  &-left {
    float: left;
    width: 50%;
    @include mobile-width() {
      width: auto;
      float: none;
      margin-bottom: 20px;
    };
  }

  &-right {
    float: left;
    width: 50%;
    img {
      width: 100%;
    }
    @include mobile-width() {
      width: auto;
      float: none;
      margin-bottom: 20px;
      img {
        width: 100%;
      }
    };

  }

  .btn-default{
    margin-top: 60px;
  }

  .col3 {
    margin-top: 40px;
    clear: both;
    @include mobile-width {
      padding:0 20px 40px;
    };
    .col3-item {
      text-align: center;
        a{
            color: $textColor;
            &:hover{
                color: $baseColor;
                text-decoration: underline
            }
        }
        img{
            max-width: 100%;
        }
      @include mobile-width() {
        width: auto;
        float: none;
        margin: auto !important;
        margin-bottom: 20px !important;
        img {
          width: 100%;
        }
      };
      p {
        margin-top: 20px;
        text-align: left;
        @include mobile-width() {
          margin-top: 10px;
        };
      }
    }
    .col3-item:first-child {
      text-align: left;
      @include mobile-width() {
        text-align: center;
      };
    }
    .col3-item:last-child {
      text-align: right;
      @include mobile-width() {
        margin-bottom: 0 !important;
      };
    }
  }

}
