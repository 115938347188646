/**
トップページコンテンツ基本ラッパー
**/
.topContent {

  @include clearfix;
  position: relative;
  z-index: 100;
  padding: 70px 0;

  @include ltMaxWidth {
    width: $maxWidth;
  };
  @include mobile-width() {
    width: auto;
    height: auto;
      padding: 40px 0;
  };

  @at-root {

    &-wrap {
      .minheight &   {
      }
      @include widthControl('');
      @include ltMaxWidth {
        box-sizing: border-box;
        width: $maxWidth;
        padding-left: 20px;
        padding-right: 20px;
      };
      @include mobile-width() {
        width: auto;
      };
      margin: auto;
      position: relative;
    }

    &-heading {
      font-size: 1.7rem;
      font-weight: normal;
      position: relative;
    }

    &-title {
      position: relative;
      font-size: 2.857rem;
      font-weight: bold;
      margin-top: 27px;
    }

    &-des {
      position: relative;
      line-height: 1.8;
      margin: 28px 0;
    }

    &-heading {
      @include mobile-width(){
        font-size: 18px !important;
        margin-top: 0px !important;
      }
    }
    &-title {
      @include mobile-width(){
        font-size: 20px !important;
        margin-top: 20px !important;
      }
    }
    &-des {
      @include mobile-width(){
        margin-top: 20px !important;
      }
    }

  }


}

body {
  &.hide {
    transition: .5s;
    opacity: 0;
  }
  &.show {
    animation-name: showPage;
    animation-duration: .5s;
  }
}

@keyframes showPage {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
