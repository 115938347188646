.mainVisual-under.service {
    background: url(../img/service/kv.jpg) no-repeat center 0 / cover;
}

.content-service {
    @include mobile-width {
        .btn-default {
            display: block;
        }
    }
    ;
    &.bgGray {
        padding: 0;
    }
    .breadCrumb {
        //    margin-bottom: 0;
    }
    @at-root {
        .contentSection {
            &-inner {
                position: relative;
                z-index: 1;
            }
            &-lead {
                @include mobile-width {
                    font-size: 20px;
                }
            }
            &-des {
                margin-bottom: 20px;
            }
            .topContent--division-bg {
                background-size: 120%;
            }
            &-service-wrap {
                //margin: 0;
            }
            .col3 {
                padding: 0;
                &-item img {
                    width: auto;
                }
            }
            /*    .contentSection-bgImage {
        height: 350px;
        .contentSection-title {
            @include mobile-width {
                text-align: center;
            }
            ;
            img {
                padding-right: 20px;
                @include mobile-width {
                    width: 130px;
                    display: block;
                    margin: auto;
                }
                ;
            }
            font-size: 1;
        }
        .contentSection-lead {
            font-size: 40px;
            font-weight: normal;
            color: $textColor;
            @include mobile-width {
                text-align: center;
                font-size: 25px;
            }
            ;
        }
        &.ch {
            background-image: url(../img/service/bg-ch.jpg);
        }
        &.platform {
            background-image: url(../img/service/bg-platform.jpg);
        }
        &.web {
            background-image: url(../img/service/bg-web.jpg);
        }
    }*/
        }
    }
    @at-root {
        &-contents {
            background: #fff;
            width: 100%;
            border-top: 1px solid #D8D8D8;
            border-bottom: 1px solid #D8D8D8;
            display: table;
            padding: 45px 0;
            @include mobile-width {
                width: auto;
                display: block;
                padding: 25px 20px;
            }
            ;
            .is-right {
                width: 61%;
                display: table-cell;
                padding-left: 6%;
                @include mobile-width {
                    margin: 20px 0 10px;
                    display: block;
                    width: auto;
                    padding-left: 0;
                }
                ;
            }
            &-wrap {
                @include clearfix;
                @include widthControl('lower');
                @include mobile-width() {
                    width: auto;
                }
                ;
                margin: 0 auto;
            }
            &-img {
                width: 33%;
                display: table-cell;
                text-align: center;
                vertical-align: middle;
                @include mobile-width {
                    width: auto;
                    display: block;
                }
                ;
                >img {
                    width: 60%;
                    @include mobile-width {
                        width: 50%;
                    }
                    ;
                }
            }
            &-title {
                font-size: 18px;
                @include mobile-width {
                    text-align: center;
                }
            }
            &-link {
                @include mobile-width {
                    text-align: center;
                }
                &>a {
                    color: $baseColor;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
            &-des {
                width: 90%;
                line-height: 1.8;
                margin-top: 24px;
                @include mobile-width {
                    width: auto;
                    margin-top: 12px;
                }
                ;
            }
            // コンテンツとコンテンツの間はborder-topなし
            &+& {
                border-top: none;
            }
        }
    }
    // 幅をデザインに合わせる
    .service-desc-area {
        .contentSection-title,
        .contentSection-lead,
        .contentSection-wrap {
            width: 430px;
            @include mobile-width {
                width: auto;
            }
            ;
        }
    }
    .service-list {
        margin-top: 30px;
        &-item {
            color: $textColor;
            font-size: 18px;
            transition: .3s all;
            list-style: none;
            &:before {
                content: '\f054';
                font-family: 'FontAwesome';
                margin-right: 10px;
                font-size: 14px;
                color: #037AFF;
            }
            &:hover {
                color: $baseColor;
            }
        }
        &-item+&-item {
            margin-top: 16px;
        }
    }
}
