/* サブページ */
.content-privacy,
.content-jimdo,
.content-security,
.conetnt-isms,
.content-riskmanagement {

  .contentSection {
    &-title {
      font-size: 20px;
    }
    &-wrap {
      margin-top: 28px;
      h4 {
        font-size: 16px;
      }
    }
  }
}

.contentSection.contact-box {
  .contentSection-title {
    font-size: 26px;
    text-align: center;
  }
  .contentSection-wrap {
    padding: 20px;
    border: 2px solid #DCDCDC;
    dl {
      font-size: 16px;
    }
    dt {
      font-weight: bold;
      margin-top: 10px;
    }
  }
}
