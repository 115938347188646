.content-privacy {
	.history-box{
		.contentSection-title{
			font-size: 16px;
		}
		dl{
			display: flex;
			width: 100%;
			max-width: 250px;
		}
		dt,dd{
			width: 50%;
		}
	}
	.contentSection-wrap {
		.privacy-declaration {
			margin-bottom: 50px;
		}

		ul {
			li {
				list-style: none;

				&:before {
					content: "- ";
				}
			}
		}
		.table{
			font-size: 16px;
			th, td {
				padding: 10px 0;
			}
			td {
				font-size: 14px;
			}
		}

	}
}
