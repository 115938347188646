.bgGray.topNews {
    padding: 0;
}

.news {
    &-title-wrapper {
        overflow: hidden;
        margin-bottom: 30px;
        @include mobile-width() {}
        ;
        .topContent-heading {
            float: left;
        }
    }
	
	&-emergency{
		margin-bottom: 60px;
		@include mobile-width() {
			margin-bottom: 40px;
		}
		.topContent-heading{
			margin-bottom: 20px;
		}
		.emergency-list{
			> li{
				margin-bottom: 3px;
			}
			.btn-emergency {
				box-sizing: border-box;
				color: #333333;
				display: block;
				padding: 13px 40px 13px 23px;
				position: relative;
				transition: .2s;
				background-color: #efefef;
				&:after {
					content: '\f105';
					font-family: 'fontAwesome';
					font-size: 20px;
					vertical-align: middle;
					position: absolute;
					top: 50%;
					right: 10px;
					margin-top: -12px;
				}
			}
			&-date{
				font-size: 12px;
				padding-right: 30px;
				@include mobile-width() {
					padding-right: 0;
					display: block;
					margin-bottom: 5px;
				}
			}
		}
		
	}
	
    &-more {
        float: right;
        a {
            color: $baseColor;
            text-decoration: underline;
        }
        @include mobile-width() {
            display: none;
        }
    }
    &-contents {
        overflow: hidden;
        display: flex;
        @include mobile-width() {
            display: block;
        }
        ;
        &-item {
            //float: left;
            display: flex;
            width: 25%;
            box-sizing: border-box;
            font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "Meiryo UI", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
            position: relative;
            color: $textColor;
            @include mobile-width() {
                display: block;
                width: auto;
                border: 1px solid #ccc;
                margin-bottom: 20px;
            }
            &:hover {
                img {
                    transform: scale(1.1);
                }
            }
            &-inner {
                margin: 0 5px;
                background: #fff;
                height: auto;
                width: 100%;
				position: relative;
                @include mobile-width() {
                    margin: 0;
					height: 372px;
                }
            }
            .thumbnail {
                width: 100%;
                overflow: hidden;
                position: relative;
				height: 186px;
				background: url("../img/common/logo_kddi.svg") no-repeat center #fff;
                img {
                    width: 100%;
                    transition: .3s;
                    position: absolute;
					top: 0;
					bottom: 0;
					margin: auto;
                }
				@include mobile-width() {
					height: 222px;
				}
            }
            &-meta {
                overflow: hidden;
                padding: 10px 20px;
            }
            &-date,
            &-category {
                font-size: 12px;
                color: $textColor;
                display: inline-block;
            }
            &-category {
                float: right;
            }
            &-date {
                color: #6E6E6E;
            }
            &-category {
				min-width: 100px;
				box-sizing: border-box;
				background-color: #4a99ed;
				color: #fff;
				font-size: 11px;
				font-weight: bold;
				line-height: 1;
				padding: 6px 0;
				text-align: center;
				position: absolute;
				top: 0;
				left: 0;
            }
            &-title {
                font-weight: normal;
                font-size: 16px;
                padding: 10px 20px;
                @include mobile-width() {
                    max-height: 65px;
                    overflow: hidden;
                }
                
            }
            &-title>a {
                color: #222;
                &:hover {
                    color: $baseColor;
                }
            }
        }
    }
	&-btn{
		text-align: center;
		padding-top: 50px;
		@include mobile-width() {
			display: none;
		}
	}
}
