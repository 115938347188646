.pagination {
  text-align: center;
  margin: 50px 0;
  ul {
    list-style: none;
    display: inline-block;
    padding: 0;
    margin: 0 20px;
  }
  li {
    display: inline-block;
    border: 1px solid #ccc;
  }

  &-backlink,
  &-forwardlink {
    display: inline-block;
    padding: 0;
    height: 30px;
    line-height: 30px;
    border: 1px solid #ccc;
    border-radius: 30px;
    a {
      padding: 0 15px;
      &:hover {
       border-radius: 30px;
      }
    }
  }
  & &-item {
    list-style: none;
    height: 30px;
    padding: 0;
    line-height: 30px;
    border-right: 0;
    &:first-child {
      border-radius: 2px 0 0 2px;
    }
    &:last-child {
      border-right: 1px solid #ccc;
      border-radius:  0 2px 2px 0;
    }
    > a,
    > span {
      padding: 0 10px;
    }
  }

   a {
     display: block;
     &:hover {
       text-decoration: none;
       background: $baseColor;
       color: #fff;
     }
   }
}
