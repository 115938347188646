.topContent--about {

  // height: 390px;

  @include ltMaxWidth {
    width: $maxWidth;
    overflow: hidden;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
  };

  @include mobile-width {
    width: auto;
    padding: 40px 0px;
  };

  .about-bg {
      background: url("../img/index/bg_aboutus.jpg") #fff no-repeat center / cover;
      width: 100%;
      top: -10px;
      left: -10px;
      height: 550px;
      overflow: hidden;
      @include ltMaxWidth {
          width: 110%;
      }
    }
}

/**
*  背景に画像が敷かれているコンテンツの記述
**/
.bgImage {

  position: relative;
  background-size: cover;
  &-heading {
    font-size: 28px;
    position: relative;
    @include mobile-width {
      font-size: 16px;
    }
  }
  &-title {
    position: relative;
    font-size: 40px;
    font-weight: bold;
    margin-top: 27px;
    @include mobile-width {
      font-size: 24px;
      margin-top: 12px;
    };
  }
  &-des {
    position: relative;
    width: 46%;
    line-height: 1.8;
    margin-top: 44px;
    @include mobile-width() {
      width: auto !important;
      margin: 0;
      margin-top: 16px;

    };
  }

  &-wrap{
    @include widthControl('');
    box-sizing: border-box;
    margin: 0 auto;
    position: relative;
    @include mobile-width() {
      width: auto;
    };
  }

  &-heading{
    padding-top: 25px;
    @include mobile-width() {
      padding-top: 0;
    };
  }

  &-des{
    box-sizing: border-box;
    padding-right: 4%;
  }

  &-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 400px;
      background-size: cover;
      z-index: -1;
  }
}
