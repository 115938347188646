.header {
    width: 100%;
    margin: auto;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1030;
    box-shadow: 0px -2px 8px 0px #6A6B76;
    /*    @include mobile-width {
        position: fixed;
    }
;*/
    /*常にヘッダートップに固定のため*/
    @include ltMaxWidth {
        /*width: auto;*/
    }
    ;
}

.header-nav-logo {
    @include clearfix;
    float: left;
    /*background: url(../img/common/logo_kddi_mono.svg) no-repeat;
    background-size: cover;*/
    a {
        margin-right: 10px;
    }
    img {
        transition: .2s;
    }
    .lower & {
        img {
            opacity: 1;
            /*常に背景白のため*/
            @include mobile-width {
                opacity: 1;
            }
            ;
        }
    }
    .lower.lower--nav-color-gray & {
        img {
            opacity: 1;
        }
    }
    .header_on & {
        img {
            opacity: 1;
        }
    }
    @include mobile-width() {
        float: none;
        position: absolute;
        top: 10px;
        left: 10px;
        &-kddi {
            img {
                width: 100px;
            }
        }
        &-kddi30th {
            img {
                width: 50px;
            }
        }
    }
}

// ロゴ部分・ナビゲーションのスタイル
.header {
    &-nav {
        @include clearfix;
        max-width:1160px;
        @include ltMaxWidth {
            padding: 15px 10px;
            box-sizing: border-box;
        }
        ;
        margin: 0 auto;
        padding: 15px 0;
        @include mobile-width() {
            width: 100%;
            position: fixed;
            background: #fff;
            height: 60px;
            padding: 10px 0;
            margin: 0;
        }
        &-contents {
            @include clearfix;
            float: right;
            /*      &-language{
        float: left;
        margin-top: 20px;
        margin-right: 32px;

        > li{
          display: inline-block;
          margin-left: 8px;
          @include mobile-width() {
            display: none;
          }
        }
        }*/
            /*言語切り替えをナビゲーションの中に配置したため*/
            &-search {
                float: left;
                margin-top: 2px;
                margin-right: 35px;
                &-icon {
                    cursor: pointer;
                    display: inline-block;
                }
            }
            .hamburger {
                float: left;
                margin-top: 18px;
                @include mobile-width() {
                    float: none;
                    position: absolute;
                    margin-top: auto;
                    top: 23px;
                    right: 15px;
                    &-box {
                        margin: 0;
                    }
                }
                ;
            }
        }
    }
    .gnav {
        float: right;
        font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "Meiryo UI", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
        margin-top: 5px;
        position: relative;
        @include mobile-width() {
            width: 100%;
            text-align: center;
            position: fixed;
            margin: 0;
            ul {
                li {
                    float: none;
                    margin: 0;
                    &:not(:first-child) {
                        margin: 0;
                    }
                    a {
                        display: block;
                        height: 50px;
                        line-height: 50px;
                        background: #fff;
                        border-bottom: 1px solid #ccc;
                    }
                    &:first-child a {
                        border-top: 1px solid #ccc;
                    }
                }
            }
        }
        &-wrap {
            @include clearfix;
            @include mobile-width() {
                overflow: auto;
            }
        }
        &-item {
            float: left;
            >a {
                color: $textColor;
                font-size: 16px;
                font-weight: bold;
                &.current {
                    color: $baseColor;
                }
				&.is-active {
					color: $baseColor;
				}
                &:hover {
                    color: $baseColor;
                    text-decoration: underline;
                    @include mobile-width() {
                        text-decoration: none;
                    }
                }
            }
            &:not(:first-child) {
                margin-left: 25px;
            }
        }
        &-item2 {
            margin-left: 45px;
            float: left;
            >a {
                color: $textColor;
                font-size: 16px;
                font-weight: bold;
                &.current {
                    color: $baseColor;
                }
				&.is-active {
					color: $baseColor;
				}
                &:hover {
                    color: $baseColor;
                    text-decoration: underline;
                    @include mobile-width() {
                        text-decoration: none;
                    }
                }
            }
        }
        &-language {
            float: left;
            /*            margin-top: 20px;
            margin-right: 32px;*/
            /*言語切り替えをナビゲーションの中に配置したため*/
            >li {
                display: inline-block;
                margin-left: 8px;
                @include mobile-width() {
                    display: none;
                }
            }
        }
        @at-root {
            // スマートフォン用のスタイル
            &-wrap-group {
                overflow: auto;
            }
            &-wrap--sp {
                .gnav-item {
                    a {
                        font-size: 13px;
                        letter-spacing: .1em;
                        color: $textColor;
                    }
                    &.has-child {
                        position: relative;
                        &>a:after {
                            position: absolute;
                            top: 0px;
                            right: 20px;
                            content: "\f107";
                            font-family: "fontAwesome";
                            font-weight: normal;
                            font-size: 30px;
                            transition: .3s;
                        }
                        &.on>a:after {
                            transform: rotate(180deg);
                        }
                        &>a+ul {
//                            height: 0;
//                            overflow: hidden;
//                            max-height: 0;
                            transition: .5s ease-out;
                        }
                        &.on>a+ul {
                            height: auto;
                            max-height: 50em;
                        }
                    }
                    .child-nav {
                        li a {
                            background: #efefef;
                        }
                    }
                }
            }
            &-wrap--service {
                background: $bgGray;
                .gnav-item.gnav-item--service {
                    margin: 10px !important;
                    a {
                        height: 40px;
                        line-height: 40px;
                        border: 0;
                    }
                    img {
                        width: 80px;
                        vertical-align: middle;
                        &[src$="katachi.svg"] {
                            width: 140px;
                        }
                    }
                }
            }
            &-wrap--utilities {
                background: $bgGray;
                display: flex;
                .gnav-item--utilities {
                    width: 50%;
                    a {
                        margin: 10px;
                        border: 0;
                        color: $textColor;
                    }
                    &:first-child a {
                        margin-right: 5px;
                        border: 0;
                    }
                    &:last-child a {
                        margin-left: 5px;
                    }
                }
            }
        }
    }
}

// 日本語・英語の切り替え部分スタイル
.gnav-language {
    a {
        @include hoverLine;
        color: $textColor;
        font-size: 12px;
    }
    .on {
        @include underLine;
    }
}

// ヘッダー展開前 ナビゲーション
.header_off {
    .header-nav-wrap {
        background-color: #fff;
        height: auto;
        @include mobile-width() {
            height: 60px;
        }
    }
    .gnav {
        top: 0;
        margin-top: 15px;
        @include mobile-width() {
            left: 0;
            top: 60px;
            ul {
                display: none;
            }
        }
        ;
    }
}

// ヘッダー展開後 ナビゲーション
.header_on {
    .header-nav-wrap {
        background-color: #fff;
        height: 90px;
        @include mobile-width() {
            height: 60px;
        }
    }
    .gnav {
        top: 0;
        margin-top: 15px;
        @include mobile-width() {
            background-color: rgba(107, 108, 118, 0.6);
            left: 0;
            top: 60px;
            margin-top: auto;
            &>ul {
                display: block;
            }
        }
        ;
    }
    /*    .header-nav-contents-language,
    .header-nav-contents-search {
        display: none;
    }*/
}
