// KeyVisual部分
body.hide {
    .keyVisual div {
        display: none;
    }
}

.keyVisual {
    height: 70vh;
    position: relative;
    overflow: hidden;
    @include mobile-width() {
        height: 70vh;
    }
    .keyVisual-message {
        position: relative;
        // top: 20%;
    }
    .top_message {
        display: block;
        color: #fff;
        @include mobile-width() {
            font-size: 38px;
            text-align: center;
        }
        ;
    }
    @at-root {
        &-spimage {
            width: 100%;
        }
        &-movie {
            position: relative;
            height: 100vh;
            div {
                position: fixed;
                top: 0;
                left: 0;
                min-width: 100%;
                min-height: 100%;
                background-size: cover;
                @include mobile-width() {
                    display: none;
                }
                ;
            }
        }
        &-content {
            position: absolute;
            width: 100%;
            height: 100vh;
            min-height: 500px;
            top: 0;
            left: 0;
            z-index: 10;
        }
        &-message {
            @include widthControl('');
            @include mobile-width() {
                width: auto;
            }
            ;
            height: 100vh;
            margin: 0 auto;
        }
        &-title {
            @include widthControl('');
            @include ltMaxWidth {
                width: $maxWidth;
                padding-left: 10px;
                padding-right: 10px;
            }
            ;
            @include mobile-width() {
                width: auto;
                margin: 10px;
            }
            ;
            font-size: 5.714rem;
            font-weight: 400;
            position: relative;
            top: 150px;
            margin: 0 auto;
            text-shadow: 0 0 15px #000000ad;
        }
        &-scrollbtn-wrap {
            box-sizing: border-box;
            position: absolute;
            width: 100%;
            bottom: 50px;
            left: 0;
            text-align: center;
            padding: 20px;
        }
        &-scrollbtn {
            $arrow-width: 20px;
            $arrow-height: 20px;
            $arrow-span: 10px;
            position: relative;
            margin: auto;
            &:after,
            span:before,
            span:after {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                display: block;
                width: $arrow-width;
                height: $arrow-height;
                transform: rotate(45deg);
                margin: auto;
                border: 1px solid #fff;
                border-top: none;
                border-left: none;
                opacity: 0;
            }
            &.arrow-active:after {
                animation: blink .8s ease-in-out alternate;
            }
            &.arrow-active span {
                &:before {
                    top: $arrow-span;
                    animation: blink .8s ease-in-out .2s alternate;
                }
                &:after {
                    top: $arrow-span * 2;
                    animation: blink .8s ease-in-out .4s alternate;
                }
            }
        }
        //
        // &-scrollbtn {
        //   position: relative;
        //   &-btn {
        //     position: relative;
        //   }
        //   &-ring {
        //       position: absolute;
        //       top: -4px;
        //       left: -4px;
        //       width: 70px;
        //       height: 70px;
        //       border-radius: 70px;
        //       background-color: transparent;
        //       border: 3px solid rgba(255,255,255,1);
        //       animation-name: water-drop;
        //       animation-duration: 1.4s;
        //       animation-iteration-count: infinite;
        //       animation-timing-function: linear;
        //   }
        //   width: 70px;
        //   height: 70px;
        //   border-radius: 70px;
        //   background-color: transparent;
        //   box-sizing: border-box;
        //   color: #fff;
        //   border: 1px solid #fff;
        //   display: inline-block;
        //   line-height: 70px;
        //   text-align: center;
        //   animation-name: water-drop-inner;
        //   animation-duration: 1.4s;
        //   animation-iteration-count: infinite;
        //   animation-timing-function: linear;
        // }
        //
        //
    }
}

@keyframes water-drop-inner {
    0% {
        transform: scale(1);
    }
    5% {
        transform: scale(1.05);
    }
    10% {
        transform: scale(1);
    }
}

@keyframes water-drop {
    0% {
        opacity: 0;
        transform: scale(1);
    }
    10% {
        opacity: 1;
        transform: scale(1.2);
    }
    20% {
        opacity: 0.5;
        transform: scale(1.4);
    }
    30% {
        opacity: 0;
        transform: scale(1.6);
        top: 0;
        left: 0;
        border-width: 0;
    }
    100% {
        opacity: 0;
    }
}

@keyframes blink {
    0% {
        opacity: 0;
    }
    80% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
