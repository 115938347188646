
.minheight {
  .topContent--vision {
    padding: 50px 0 100px;
  }
}

.topContent--vision{
  position: relative;
  box-sizing: border-box;
  // padding: 60px 0 224px 68px;
  overflow: hidden;
  z-index: 1;

  @include ltMaxWidth {
    width: $maxWidth;
    box-sizing: border-box;
    padding-left: 20px !important;
    padding-right: 20px !important;
  };

  @include mobile-width(){
    width: auto;
    padding: 40px 10px;
  }

  @at-root {
    &-cover {
      position: absolute;
      top: -2.5%;
      left: -2.5%;
      width: 105%;
      height: 105%;
      background: url("../img/index/bg_vision.jpg") no-repeat center;
      background-size: cover;
      z-index: -1;
    }
    &-wrap {
      @include widthControl('');
      @include mobile-width(){
        width: auto;
      };
      position: relative;
      margin: auto;
    }
    &-heading,
    &-title,
    &-des{
      color: #fff;
      position: relative;
      @include mobile-width(){
        width: 100%;
      }
    }

  }

  .btn-default{
    margin-top: 60px;
    @include mobile-width(){
      margin-top: 50px;
      width: 100%;
    }
  }

  .vision_img {
    position: absolute;
    right: 10px;
    top: 20px;
    @include mobile-width(){
      display: none;
    }
  }

}
