.access{
  @include ltMaxWidth {
    width: $maxWidth;
  };
  @include mobile-width {
    width: auto;
  };
  @at-root {
    &-inner {
      @include widthControl('');
      margin: auto;
      padding: 70px 0 0;
      width: 100%;
      @include mobile-width() {
        padding-top: 0px;
        width: auto;
        margin: 0 20px;
      };

    }
  }

  background: #fff;

  &-title{
    font-size: 1.286rem;
  }


  &-contents{
    @include clearfix;
    box-sizing: border-box;
    margin: 56px auto 0;
    @include ltMaxWidth {
      width: $maxWidth;
      box-sizing: border-box;
      padding: 0 10px;
    };
    @include mobile-width() {
      padding: 0;
      width: auto;
    };
    &-item{
      &-wrap {
        overflow: hidden;
      }
      width: 29.5%;
      margin-bottom: 60px;
      margin-left: 5%;
      float: left;
      @include mobile-width() {
        width: auto;
        float: none;
        margin-left: 0;
      };
      &-title{}
      &-direction {
        margin-top: 15px;
        @include mobile-width() {
          height: auto;
        };
      }
      &-des{
        line-height: 1.8;
        margin-top: 14px;
      }
      &-link{
        margin-top: 20px;

        > a{
          @include hoverLine;
          color: $textColor;
        }
      }
    }
    &-item:first-child{
        margin-left: 0;
    }
  }

  &-map-toggle {
      display: flex;
    li {
      width: 33.3333333%;
     /* float: left;*/
     /* text-align: center;*/
     /*height: 50px;*/
     /*line-height: 50px;*/
      box-sizing: border-box;
      border: 1px solid #ccc;
      border-left: none;
      &.current {
        a {
          background: #f4f4f4;
        }
      }
    }
    li:first-child {
        border-left: 1px solid #ccc;
    }
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      padding: 20px;
        @include mobile-width() {
            padding: 10px;
        }
      color: $textColor;
    }
  }
}

#gmap{
  width: 100%;
  height: 500px;
  @include ltMaxWidth {
    width: $maxWidth;
  };
  @include mobile-width {
    width: 100%;
  };
}
