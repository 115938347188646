.mainVisual-under.recruit {
  background: url(../img/recruit/kv.jpg) no-repeat center / cover;
  @include mobile-width {
    background-position: center 30px;
  };
}

.content-recruit {
  margin: auto;
  .btn-default {
    text-align: center
  }
}

.recruit-content-inner {
  margin-bottom: 60px;
  @include mobile-width {
    .btn-default {
      margin: 10px;
      display: block;
    }
  };
}

.content-header {
  font-size: 28px;
  margin: 50px 0 25px;
  @include mobile-width {
    margin-left: 10px;
    margin-right: 10px;
  };
}

.content-subheader {
  font-size: 20px;
  margin: 50px 0 25px;
  @include mobile-width {
    margin-left: 10px;
    margin-right: 10px;
  };
}

.recruit-carrer-list {
  @at-root {
    &-items {
      overflow: hidden;
      border-left: 1px solid $textColor;
      @include mobile-width {
        margin: 10px;
        border: 0;
      };
    }
    &-item {
      height: 420px;
      float: left;
      width: 33.33333333333%;
      border: 1px solid $textColor;
      border-left: none;
      box-sizing: border-box;
      @include mobile-width {
        float: none;
        width: auto;
        margin-bottom: 20px;
        border-left: 1px solid $textColor;
      };
      &.potential {
        img {
          margin-bottom: 0;
        }
        height: 328px;
      }
    }
  }
  h5 {
    padding: 20px;
    font-size: 21px;
    text-align: center;
    img {
      display: block;
      margin: auto;
      margin-bottom: 15px;
      @include mobile-width {
        width: auto;
        height: 60px;
      };
    }
  }
  p {
    padding: 20px;
    margin: 0;
  }

  ul {
    padding: 20px;
    margin: 0;
    li {
      list-style: none;
      font-size: 17px;
      margin-bottom: 9px;
      white-space: nowrap;
      color: $baseColor;
      &:before {
        content: '\f054';
        font-family: 'FontAwesome';
        font-size: 12px;
        margin-right: 10px;
      }
    }
  }
}

.recruit-workstyle-list {
  width: 48%;
  @include mobile-width {
    width: auto;
  };
  &.right {
    float: right;
  }
  &.left {
    float: left;
  }
  @at-root {
    &-wrap {
      margin: 50px 0;
      overflow: hidden;
    }
    &-items {
    }

    &-item {
      @include mobile-width {
        .recruit-workstyle-list:not(:last-child) &:last-child {
            border-bottom: 0;
        }
      };
      overflow: hidden;
      margin-bottom: 21px;
      height: 130px;
      @include mobile-width {
        height: auto;
        padding: 20px 10px;
        border-top: 1px solid #ccc;
        margin-bottom: 0;
        &:last-child {
          border-bottom: 1px solid #ccc;
        }
      };
    }

    &-thumbnail {
      float: left;
      margin-right: 20px;
    }

    &-content {
      overflow: hidden;
      p {
        margin: 0;
      }
      h2 {
        margin-bottom: 15px;
        font-size: 20px;
      }
    }
  }
}

.recruit-content-image {
  margin: 50px 0;
  @include mobile-width {
    margin: 20px 0;
  };
}
