// メディアクエリ
@mixin max-screen($break-point) {
  @media screen and (max-width: $break-point) {
    @content;
  }
}
@mixin min-screen($break-point) {
  @media screen and (min-width: $break-point) {
    @content;
  }
}

@mixin screen($break-point-min, $break-point-max) {
  @media screen and (min-width: $break-point-min) and (max-width: $break-point-max) {
    @content;
  }
}

@mixin mobile-width () {
  @include max-screen($breakpoint-mobile) {
    @content
  };
}

// clearfix用
@mixin clearfix {
  &::after {
    clear: both;
    content: "";
    display: block;
  }
}

// 下線用
@mixin underLine{
  position: relative;

  &:before{
    width: 100%;
    height: 2px;
    background-color: $textColor;
    content: "";
    position: absolute;
    bottom: -6px; left: 0;
  }
}

@mixin widthControl ($isLower) {

  @if $isLower != '' {
    width: $maxLowerWidth;
  }
  @else {
    width: $maxWidth;
  }
  @include mobile-width {
    width: auto;
  };

}

@mixin ltMaxWidth () {
  @include max-screen($maxWidth) {
    @content;
  }
}

@mixin gtMaxWidth () {
  @include min-screen($maxWidth) {
    @content;
  }
}

// リンクホバー時の下線用
@mixin hoverLine{
  position: relative;

  &:before{
    width: 0;
    height: 2px;
    background-color: $textColor;
    content: "";
    position: absolute;
    bottom: -6px; left: 0;
  }
  &:hover:before{
    width: 100%;
  }
}


@mixin content-default-style {
  // コンテンツのデフォルトスタイル
  p {
    font-size: 16px;
    margin: 20px 0;
  }
  ul,ol {
    margin: 20px 0 20px 20px;
  }
  ul {
    font-size: 16px;
    li {
      list-style: disc outside;
    }
  }
  ol {
    li {
      list-style: decimal outside;
    }
  }
  a {
    color: $baseColor;
    &.btn-default {
      color: $textColor;
    }
    &:hover {
      text-decoration: underline;
    }
  }
  @include mobile-width {
    margin: 30px 0;
  };
}
