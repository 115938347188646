.mainVisual-under.corporate {
	background: url(../img/corporate/kv.jpg) no-repeat center 0 / cover;
}

.content-corporate {
	.contentSection-bgImage {
		background-image: url(../img/corporate/index_bg.jpg);
	}
}

.content-corporate-access {
	.access-contents {
		@include widthControl('lower');
		margin: auto;
		padding: 0;
	}
}

.content-corporate-profile {
	.table {
		margin-top: 80px;
	}
}

.content-corporate-message {
	.head-img-name {
		font-size: 28px;

		@include mobile-width {
			font-size: 16px;
		}

		;
	}
}

.content-corporate-business {
	
	.contentSection-wrap {
		margin: 30px 0;
		line-height: 1.8;
	}
	
	.image-wrap {
		padding: 50px 0 0;
		text-align: center;
		@include mobile-width {
			width: 100%;
			padding: 0;
		}
	}
	
	.image-wrap-left{
		padding-right: 50px;

		@include mobile-width {
			padding-right: 0;
		}
	}
	.image-wrap-right{
		padding-left: 50px;

		@include mobile-width {
			padding-left: 0;
		}
	}
	
	.brand-list{
		&>li{
			margin-bottom: 10px;
		}
	}

	.contentSection-ch {
		padding: 120px 0 0;
		margin-bottom: 60px;
		@include mobile-width {
			padding: 60px 0 0;
		}

		.image-wrap {
			@include mobile-width {
				width: 100%;
			}
		}

		.image-wrap > img {
			max-width: 250px;
			width: 85%;
		}

	}

	.contentSection-web {
		background-color: #f9f9f9;
		padding: 80px 0 35px;

		@include mobile-width {
			padding: 60px 0;
		}
	}

	.contentSection-platform {
		padding: 20px 0 0;

		@include mobile-width {
			padding: 0;
		}
	}

	.grid-web {
		display: grid;
		gap: 10px;
		grid-template-columns: repeat(6, 1fr);
		grid-template-rows: 1fr 1fr;
		align-items: center;
		justify-items: center;

		.grid-item1 {
			grid-column: 1/4;
			grid-row: 1;
			width: 85%;
		}

		.grid-item2 {
			grid-column: 4/7;
			grid-row: 1;
			width: 85%;
		}

		.grid-item3 {
			grid-column: 2/4;
			grid-row: 2;
			width: 85%;
		}

		.grid-item4 {
			grid-column: 3/5;
			grid-row: 2;
			width: 85%;
		}

		.grid-item5 {
			grid-column: 5/7;
			grid-row: 2;
			width: 85%;
		}
	}

	.grid-platform {
		display: grid;
		gap: 45px 20px;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: repeat(auto-fill, minmax(100px, 1fr));
		align-items: center;
		justify-items: center;
		

		.grid-item1 {
			width: 85%;
		}

		.grid-item2 {
			width: 85%;
		}

		.grid-item3 {
			width: 85%;
		}

		.grid-item4 {
			width: 85%;
		}

		.grid-item5 {
			width: 85%;
		}

		.grid-item6 {
			width: 85%;
		}

		.grid-item7 {
			width: 85%;
		}
	}
}



.content-philosophy {
	.philosophy {
		background-image: url(../img/corporate/philosophy_bg.jpg);
	}

	.philosophy-list {
		margin-top: 40px;

		@at-root {
			&-header {
				overflow: hidden;
				margin-bottom: 36px;

				span {
					display: block;
					float: left;
					width: 100px;

					@include mobile-width {
						float: none;
					}

					;
				}

				font-size: 28px;

				@include mobile-width {
					font-size: 20px;
					margin-bottom: 16px;
				}

				;
			}

			&-content {
				padding-left: 110px;
				font-size: 16px;
				margin-bottom: 36px;

				@include mobile-width {
					padding-left: 0;
				}

				;
			}
		}
	}
}
