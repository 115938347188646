.lower.lower--nav-color-gray {
    .hamburger-inner {
        &,
        &::before,
        &::after {
            background-color: $textColor !important;
        }
    }
    .header-nav-contents-language {
        a {
            color: $textColor !important;
            &:before {
                background-color: $textColor;
            }
        }
    }
}

.lower .hamburger-inner {
    &,
    &::before,
    &::after {
        background-color: #fff;
        @include mobile-width {
            background-color: $textColor;
        }
        ;
    }
}

.lower .header_on .hamburger-inner {
    &,
    &::before,
    &::after {
        background-color: $textColor;
    }
}

.lower {
    .header-nav-contents-language {
        a {
            color: #fff !important;
            &:before {
                background-color: #fff;
            }
        }
    }
}

// 下層ページ用 MV
.mainVisual-under {
    min-height: 360px;
    @include ltMaxWidth {
        width: $maxWidth;
    }
    ;
    @include mobile-width {
        width: auto;
        min-height: 240px;
    }
    ;
    color: #fff;
    position: relative;
    &:after {
        content: "";
        min-height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .3);
        z-index: 0;
    }
    &--no-image {
        min-height: 220px;
        color: #333C4E;
        @include mobile-width {
            min-height: 150px;
        }
        ;
        &.mainVisual-under:after {
            background: none;
        }
        & &-title {
            font-size: 30px;
            margin-top: 60px;
            padding-top: 100px;
            @include mobile-width {
                font-size: 25px;
                padding-top: 80px;
            }
            ;
        }
    }
    &-wrap {
        position: relative;
        @include widthControl('');
        @include mobile-width() {
            width: auto;
        }
        ;
        margin: 0 auto;
        z-index: 1;
    }
    &-title {
        display: inline-block;
        font-size: 40px;
        padding-top: 180px;
/*        span {
            margin-left: 30px;
            font-size: 18px;
            font-weight: normal;
            vertical-align: top;
        }*/
        @include mobile-width {
            padding-top: 80px;
            margin: 10px;
            font-size: 25px;
        }
        ;
    }
    &-subtitle {
        display: inline-block;
        font-size: 18px;
        margin-left: 20px;
        @include mobile-width {
            font-size: 13px;
        }
        ;
    }
    &-des {
        width: 47%;
        line-height: 2;
        margin-top: 20px;
        @include mobile-width {
            width: auto;
            margin: 0 10px;
            font-size: 13px;
            line-height: 1.5em;
        }
        ;
    }
}

// 下層ページ　MV下ナビ
.nav-under {
    @include mobile-width {
        display: none;
    };
    &-wrap {
        @include widthControl('');
        @include mobile-width() {
            width: auto;
        };
        margin: 0 auto;
		display: flex;
		justify-content: space-around;
		flex: 1 0 auto;
    }
    @at-root {
        $_nav_with: 100%;
        &--col6 {
            ul li>a {
                width: $_nav_with/6;
            }
        }
        &--col3 {
            ul li>a {
                width: $_nav_with/3;
            }
        }
    }
	&-item{
		width: 100%;
	}
    &-item>a {
        width: 100%;
        color: $textColor;
        display: block;
        float: left;
        font-size: 16px;
        padding: 20px 0;
        text-align: center;
        transition: .3s all;
        &:hover {
            background-color: $ltGray;
//            color: #fff;
        }
    }
    a.current {
        background-color: $ltGray;
//        color: #fff;
    }
}
