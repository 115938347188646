.slick-initialized {
  //padding-top: 50px;
  //margin-top: 20px;
}
.slick-prev,
.slick-next {
    height: 40px;
    width: 80px;
    background: $baseColor;
    line-height: 0px;
    font-size: 0px;
    //top: 0;
    color: transparent;
    padding: 0;
    transform: none;
	z-index: 100;
	top: calc(223px - 40px);//サムネイルのトップから40px下に位置を下げる
    &:hover, &:focus {
      background: $baseColor;
    }
    &.slick-disabled:before {
    }
    &:before {
      font-family: 'fontAwesome';
    }
}

.slick-prev {
    left: 0px;
    [dir="rtl"] & {
        left: auto;
        right: -25px;
    }
    &:before {
      content:'\f104';
    }
}

.slick-next {
    right: 0px;
    [dir="rtl"] & {
        left: -25px;
        right: auto;
    }
    &:before {
      content:'\f105';
    }
}
