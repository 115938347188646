.mainVisual-under.english {
    background: url(../img/english/kv.jpg) no-repeat center 0 / cover;
}
.content-english{
	.contentSection-title{
		padding-top: 64px;
		@include mobile-width {
			padding-top: 40px;
		};
	}
    .table{
        margin-top: 80px;
    }
}