.breadCrumb{
  @include widthControl('');
  @include ltMaxWidth {
    box-sizing: border-box;
  };
  	overflow: hidden;
	margin: 0 auto;
  	padding: 30px 0 64px;
  	@include mobile-width {
      	padding: 20px 20px 40px;
		font-size: 11px;
  	};

  &-item{
    display: inline-block;
  }

  &-item{
    position: relative;
    padding-right: 20px;
    a {
      color: $textColor;
      text-decoration: underline;
      transition: .3s all;
      &:hover{
        color: $baseColor;
      }
    }
    &:after {
      content: ">";
      position: absolute;
      top: 1px;
      right: 0;
    }
    &:last-child:after {
      content: '';
    }
  }

  &-item + &-item{
    margin-left: 10px;
  }
}


.mainVisual-under .breadCrumb{
	padding: 20px 0;
	@include mobile-width {
		padding: 10px 20px;
	};

	&-item{
		a {
			color: #fff;
			&:hover{
				color: $baseColor;
			}
		}
	}
}