.content-form {
  background-color: $bgGray;
  @include ltMaxWidth {
    width: $maxWidth;
  };
  @include mobile-width {
    width: auto;
  };
  .content-wrapper {
    @include widthControl('lower');
    margin: auto;
    padding: 60px 0;
    border: 1px solid transparent;
  }
  .form-list {
    display: flex;
    justify-content: center;
    align-content: center;
    margin-bottom: 100px;
    @include mobile-width {
      flex-wrap: wrap;
      margin: 10px;
    };
    @at-root {
      &-item {
        width: 25%;
        margin: 10px;
        height: 200px;
        line-height: 200px;
        text-align: center;
        @include mobile-width {
          width: 50%;
          margin: 0;
          box-sizing: border-box;
        };
        a {
          display: block;
          height: 100%;
          background: #fff;
          color: $textColor;
          transition: .2s;
          &.current,
          &:hover {
            background: #726F6D;
            color: #fff;
          }
        }
      }
    }
  }

  .table {
    @at-root {
      &-confirm {
        th,
        td {
          width: 50%;
        }
      }
    }
    margin-bottom: 50px;
    th {
      width: 210px;
      padding-right: 10px;
      @include mobile-width {
        width: auto;
      };
    }
    th,td {
      border: 0;
      vertical-align: middle;
    }
    th {
      vertical-align: top;
      label {
        display: block;
      }
      .label-required {
        float: right;
        @include mobile-width {
          float: none;
          text-indent: 20px;
        }
      }
    }
    .center {
      p {
        text-align: center;
      }
      text-align: center;
    }
  }

  .form-btn-box {
    text-align: center;
    form {
      display: inline-block;
    }
  }
}

.label-required {
  display: inline-block;
  color: #D0011B;
  font-size: 10px;
}

.error-text {
  color: #D0011B;
}

.radio-group {
  padding: 0 10px 30px 10px;
  overflow: hidden;
  li {
    //float: left;
      margin-bottom: 12px;
	 p {
	 margin-top: 20px;
	 font-size: 14px;
 		}
	  a {
        color: $baseColor;
        &:hover {
          text-decoration: underline;
        }
      }
  }
  @include mobile-width {
    padding-bottom: 0;
    li {
      //float: none;
    }
  };
    .radio-list{
        display: flex;
        width: 80%;
        padding: 10px 0 20px 14px;
        justify-content: space-between;
        @include mobile-width {
             flex-direction: column;
        };
        &--item{
        }
    }
}

.service_link {
    padding-bottom: 0;
  }

.name-wrap {
  float: left;
  margin-right: 10px;
  @include mobile-width {
    margin-bottom: 10px;
  };
}

.link-underline {
  text-decoration: underline;
  color: $textColor;
}

td.privacy-box {
  background: #f4f4f4;
  padding: 20px;
  h2 {
    font-size: 15px;
  }
  p {
    font-size: 14px;
  }
  .privacy-link {
    margin: 20px 0;
    a {
      color: $baseColor;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
