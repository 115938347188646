.content-sattlement {
  .contentSection-wrap {
    section {
      margin-bottom: 80px;
    }
    h4 {
      font-size: 20px;
    }
  }
}
