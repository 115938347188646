/* 削除対象 */
.content-default{

  background-color: #f9f9f9;

  p{
    line-height: 1.5;
  }
  li{
    line-height: 1.5;
  }

  ul{
    margin-left:20px;
    margin-bottom: 40px;
  }

  h3{
    margin-bottom: 25px;
  }

  h4{
    margin-bottom: 15px;
  }

  .content-wrapper {
    @include widthControl('');
    margin: auto;
    @include mobile-width() {
      width: auto;
      margin-left: 10px;
      margin-right: 10px;
    };
  }

  background: #f9f9f9;
  @include mobile-width() {
    width: auto;
  };

  margin:50px auto;

  a {
    color: $baseColor;
  }
  .content-box{
    margin-bottom: 60px;
  }
  .etc{
    margin-bottom: 40px;
  }
  .contact{
    text-align: center;
  }
  .contact_address{
    border: solid 2px #e7e9ec;
    margin-top: 15px;
  }
  ._address{
    padding:25px 0px 50px 30px;
  }
  ._address_margin{
    margin-top: 20px;
  }
}
