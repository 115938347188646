.topContent--division{

  .minheight &.vh {
    padding: 70px 0;
  }

  @include clearfix;
  position: relative;
  width: 100%;

  @include ltMaxWidth {
    width: $maxWidth;
  };

  @include mobile-width() {
    padding: 0 !important;
    width: auto;
    overflow: hidden;
    width: auto;
    .col3 {
      padding-left: 0;
      padding-right: 0;
    }
  }



  background: $bgGray;
  z-index: 1;

  .pg-canvas {
    transition: .2s;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0
  }

  .pg-canvas.active {
    opacity: 1;
  }

  &-wrap{
    position: relative;
    @include widthControl('');

    margin: auto;
    @include mobile-width() {
      width: auto;
      padding:40px 20px;
    };
  }

  &-heading,
  &-title,
  &-des{
    position: relative;
    @include ltMaxWidth {
      padding-left: 10px;
      padding-right: 10px;
      @include mobile-width {
        padding-left: 0;
        padding-right: 0;
      };
    };
  }

  &-des{
    box-sizing: border-box;
    padding-right: 4%;
    width: auto;
  }

  &-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .2;
    background: url(../img/index/kwcweb_bgimages.png) no-repeat center;
    background-size: 90%;
    z-index: -1;
  }

}
