@import "layouts/ress";
@import "layouts/variables";
@import "layouts/mixins";


// ハンバーガーメニュー用
@import "layouts/hamburgers";

// slick.css
@import "./../../../node_modules/slick-carousel/slick/slick.scss";
@import "./../../../node_modules/slick-carousel/slick/slick-theme.scss";

@import "layouts/global"; // 基本設定
@import "layouts/form"; // フォーム関連
@import "layouts/slick-custom"; // slickのカスタマイズ設定
@import "layouts/header"; // ヘッダー
@import "layouts/footer"; // フッター
@import "layouts/divisions"; // 事業一覧
@import "layouts/access"; // 地図レイアウト
@import "layouts/spinner"; // スピナー
@import "layouts/admin"; // 管理UI
@import "layouts/breadCrumb"; //パンくず
@import "layouts/content"; // コンテンツエリア基本レイアウト
@import "layouts/grid"; // TwitterBootStrap Grid System
@import "layouts/pagination"; // ページネーション
@import "layouts/serial-nav"; // エントリー切り替えボタン

// ページの基本設定
@import "page/content-default"; /* 削除対象 */

// トップページ
@import "index/index";

// 下層ページ
@import "layouts/lower";

// サービス
@import "page/service";

// 会社情報
@import "page/corporate";

// お知らせ
@import "page/news";

// 採用情報
@import "page/recruit";

@import "page/_sub-pages";

// お問い合わせ
@import "page/contact";

// プライバイシーポリシー
@import "page/privacy";

// Jimdo プライバイシーポリシー
@import "page/privacy-jimdo";

// リスクマネジメント
@import "page/riskmanagement";

// 資金運用
@import "page/sattlement";

// セキュリティー
@import "page/security";

// ISMS
@import "page/security-isms";

// サイトマップ
@import "page/sitemap";

// スタイルガイド
@import "page/styleguide";

// 英語ページ トップページ
@import "page/english";
