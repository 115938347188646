.brand-wrap {
    width: 100%;
    height: 100%;
/*    background-image: linear-gradient(270deg, rgba(255, 255, 255, 0.00) 0%, #333C4E 90%);*/
    &-outer {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
            &.cpi {
                background-image: url(../img/brands/bg_cpi.jpg);
            }
            &.jimdo {
                background-image: url(../img/brands/bg_jimdo.jpg);
            }
            &.twilio {
                background-image: url(../img/brands/bg_twilio.jpg);
        }
            &.goat {
                background-image: url(../img/brands/bg_goat.jpg);
            }
            &.kidscolleccio {
                background-image: url(../img/brands/bg_kidscolleccio.jpg);
            }
            &.canva {
                background-image: url(../img/brands/bg_canva.jpg);
            }
            &.ccvps {
                background-image: url(../img/brands/bg_ccvps.jpg);
            }
            &.ssl {
                background-image: url(../img/brands/bg_ssl.jpg);
            }
    }
    &__header {
        position: relative;
        ;
        width: 100%;
        height: 70px;
        background: #fff;
    }
    &__header__brand-logo {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        left: 50px;
        @include mobile-width () {
            left: 10px;
        }
    }
    &__header__navi {
        position: absolute;
        right: 50px;
        top: 50%;
        transform: translateY(-50%);
        @include mobile-width() {
            right: 20px
        }
        a {
            color: $textColor;
        }
    }
    &__header__navi li {
        float: left;
        font-size: 25px;
        margin-left: 30px;
        cursor: pointer;
        &.brand-wrap-close {
            img {
                vertical-align: middle;
                width: 20px;
            }
        }
    }
    &__content {
        color: #fff;
        margin: 50px;
        width: 50%;
        @include mobile-width() {
            margin: 20px;
            width: auto;
            overflow-y: scroll;
        }
    }
    &__content h2 {
        font-weight: normal;
        margin-bottom: 38px;
    }
    &__content-area {
        margin-bottom: 60px;
    }
}
