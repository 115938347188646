.serial-nav {
  @at-root {
    &-wrap {
      @include widthControl('');
      margin: 80px auto;
      position: relative;
      overflow: hidden;
    }
    &-item {
      a {
        display: block;
        width: 300px;
        padding: 10px 0;
        border: 1px solid #ccc;
        text-align: center;
        color: $baseColor;
        border-radius: 300px;
        &:hover {
          background: $baseColor;
          text-decoration: none;
          color: #fff;
        }
        @include mobile-width {
          width: auto;
          margin: 20px;
        };
      }
      &-prev {
        float: left;
        @include mobile-width {
          float: none;
        };
      }
      &-next {
        float: right;
        @include mobile-width {
          float: none;
        };
      }
    }
  }
}
