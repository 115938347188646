.footer {
    position: relative;
    z-index: 1020;
    background-color: #fff;
    padding-top: 60px;
    @include ltMaxWidth {
        width: $maxWidth;
    }
    ;
    @include mobile-width {
        padding-top: 0;
        width: auto;
    }
    ;
    @at-root {
        &--pc {
            @include ltMaxWidth {
                width: $maxWidth;
            }
            ;
            @include mobile-width {
                display: none;
            }
            ;
        }
    }
    .pagetop {
        position: absolute;
        font-size: 0;
        top: -30px;
        right: 10px;
        width: 50px;
        height: 50px;
        border: 1px solid #ccc;
        border-radius: 50px;
        background: #fff;
        text-align: center;
        &:after {
            content: '\f106';
            line-height: 50px;
            font-size: 30px;
            font-family: 'fontAwesome';
        }
    }
    &-nav {
        position: relative;
        z-index: 1020;
        height: 60px;
        width: 100%;
        background-color: #f4f4f4;
        &-wrap {
            @include widthControl('');
            position: relative;
            margin: 0 auto;
            @include mobile-width {
                width: auto;
            }
            ;
        }
        &-contents {
            @include clearfix;
            display: inline-block;
            padding: 15px 0;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            &-item {
                float: left;
                >a {
                    color: $textColor;
                    font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "Meiryo UI", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
                    font-size: 16px;
                    font-weight: bold;
                    &:hover {
                        color: $baseColor;
                        text-decoration: underline;
                    }
                }
                &:not(:first-child) {
                    margin-left: 45px;
                }
            }
        }
    }
    &--pc {
        background-color: $bgDarkGray;
        @include ltMaxWidth {
            width: $maxWidth;
        }
        ;
    }
    &-wrap {
        @include clearfix;
        @include widthControl('');
        @include ltMaxWidth {
            width: $maxWidth;
            box-sizing: border-box;
            padding: 0 10px;
        }
        ;
        @include mobile-width() {
            width: auto;
        }
        ;
        position: relative;
        margin: 20px auto 0;
        height: 160px;
    }
    &-logo {
        &-kddiDesigning {
            float: left;
        }
        &-kddiGroup {
            float: right;
        }
    }
    &-sitemap {
        @include clearfix;
        float: right;
        /*&-left {
            float: left;
        }
        &-right {
            float: right;
            margin-left: 60px;
        }*/
        &-list {
            float: left;
            margin-right: 60px;
        }
        &-item a {
            color: #fff;
            display: block;
            line-height: 2;
        }
        &-item a:hover {
            text-decoration: underline;
        }
    }
    &-sns {
        float: right;
        margin-left: 89px;
        &-item {
            display: inline-block;
            &:last-child {
                margin-left: 35px;
            }
        }
    }
    &-copyright {
        clear: both;
        color: #fff;
        display: block;
        margin-top: 8px;
        position: absolute;
        bottom: 5px;
        right: 0;
    }
}

.footer--sp {
    background: #fff;
    padding: 40px 0 0;
    @at-root {
        &-nav-contents {
            display: flex;
            &-item {
                width: 25%;
                text-align: center;
                display: flex;
                align-items: stretch;
                line-height: 100px;
                box-sizing: border-box;
                border: 1px solid #fff;
                border-left: 0;
                border-bottom: 0;
                &:last-child {
                    border-right: 0;
                }
                a {
                    display: flex;
                    justify-content: center;
                    color: #fff;
                    background: $bgDarkGray;
                    width: 100%;
                    p{
                        display: flex;
                        justify-content: center;
                        align-self: center; 
                    }
                }
            }
        }
    }
    @at-root {
        &-sitemap {
            display: flex;
            flex-wrap: wrap;
            &-item {
                display: flex;
                align-items: stretch;
                width: 50%;
                height: auto;
                background: #ccc;
                text-align: center;
                box-sizing: border-box;
                border: 1px solid #fff;
                border-left: 0;
                &:nth-child(even) {
                    border-right: 0;
                }
                &:last-child {
                   width: 100%;
                }
                a {
                    display: flex;
                    justify-content: center;
                    width: 100%;
                    padding: 20px 10px;
                    color: #fff;
                    background: $bgDarkGray;
                    p{
                        display: flex;
                        justify-content: center;
                        align-self: center;
                    }
                }
            }
        }
    }
    &-logo {
        min-height: 60px;
        position: relative;
        text-align: center;
        
        img{
            /*position: absolute;*/
            margin-top: 10px;
        }
        &-kddiDesigning{
           left: 20px;
            top: 20px;
        }
        &-kddiGroup{
           /*right: 20px;
            top:10px;*/
        }
    }
    &-copyright {
        clear: both;
        color: $textColor;
        padding: 0 20px;
        display: block;
        margin-top: 10px;
        text-align: center;
    }
}
