.topContent--project {
    background: #fff;
    &-wrap {
        width: 100%;
    }
    &-heading,
    &-title,
    &-des {
        position: relative;
        @include mobile-width() {
            width: auto;
        }
        ;
    }
    &-left {
        width: 45%;
        float: left;
    }
    &-right {
        width: 45%;
        float: right;
    }
    &-slide {
        margin-top: 60px;
        position: relative;
        &-item {
            width: 100%;
            display: none;
            >img {
                display: block;
                margin: 0 auto;
            }
            &.on {
                display: block;
            }
        }
        &-icon {
            position: relative;
            .arrow-left,
            .arrow-right {
                cursor: pointer;
                position: absolute;
                bottom: -114px;
            }
            .arrow-left {
                left: calc(50% - 65px);
            }
            .arrow-right {
                right: calc(50% - 65px);
            }
        }
    }
    &-link {
        margin-top: 100px;
        &-item {
            box-sizing: border-box;
            cursor: pointer;
            font-size: 2.857rem;
            line-height: 1.6;
            padding-left: 16px;
            position: relative;
            >span {
                font-size: 1.286rem;
                opacity: .4;
                position: absolute;
                top: 50%;
                right: 16px;
                transform: translateY(-50%);
            }
            &.on {
                background-color: #0097D7;
                color: #fff;
            }
        }
    }
}
.ServiceLeadText{
    width: $maxWidth;
    @include ltMaxWidth {
      width: 1140px;
    };
    margin: 0 auto;
    @include mobile-width() {
        width: auto;
    };
}
.projectList {
    overflow: hidden;
	position: relative;
	margin:0 auto;
	max-width:2000px;
    margin-top: 50px;
    @include mobile-width {
        margin-left: -20px;
        margin-right: -20px;
    }
	
	.projectList--bg{
		max-width: 100%;
	}
	.projectList--btn{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		&:hover{
			color: rgba(255,255,255,0.7);
			border: 1px solid rgba(255,255,255,0.7);
		}
	}
    
    /*&-item {//20181217_topServiceのサービス表記を修正したため、コメントアウト
        transition: .2s ease-out;
        width: 33.333333%;
        height: 200px;
        position: relative;
        overflow: hidden;
        background-position: center;
        background-size: 130%;
        filter: grayscale(100%);
        @include mobile-width() {
            width: 50%;
            height: 150px;
            background-size: 180%;
        }
        ;
        float: left;
        @at-root {
            &.cpi {
                background-image: url("../img/index/bg_cpi.jpg");
            }
            &.ccvps {
                background-image: url("../img/index/bg_ccvps.jpg");
            }
            &.ssl {
                background-image: url("../img/index/bg_ssl.jpg");
            }
            &.jimdo {
                background-image: url("../img/index/bg_jimdo.jpg");
            }
            &.twilio {
                background-image: url("../img/index/bg_twilio.jpg");
            }
            &.goat {
                background-image: url("../img/index/bg_goat.jpg");
            }
            &.kidscolleccio {
                background-image: url("../img/index/bg_kidscolleccio.jpg");
            }
            &.canva {
                background-image: url("../img/index/bg_canva.jpg");
            }
            &:hover,
            &.on {
                background-size: 135%;
                @include mobile-width() {
                    width: 50%;
                    background-size: 190%;
                }
                ;
                filter: grayscale(0);
                .projectList-item-icon {
                    opacity: 0;
                }
                .projectList-item-icon.hover {
                    opacity: 1;
                }
            }
            &-icon {
                transition: .3s ease-out;
                position: absolute;
                top: 0;
                left: 0;
                text-align: center;
                line-height: 200px;
                @include mobile-width() {
                    line-height: 150px;
                }
                img {
                    vertical-align: middle;
                    max-width: 70%;
                }
                width: 100%;
                height: 100%;
                background: rgba(0,
                0,
                0,
                .6);
                &.hover {
                    background: none;
                    opacity: 0;
                }
            }
        }
    }*/
}

#projectWrap {
    position: fixed;
    transition: .5s;
    z-index: 20000;
    width: 290px;
    height: 200px;
    background: #000;
}

#projectWrap.show {
    top: 0 !important;
    left: 0 !important;
    width: 100%;
    height: 100%;
}
