.topBnr{
  @include clearfix;
  width: 980px;
  @include mobile-width() {
    width: auto;
    text-align: center;
    margin: 20px;
    img {
      width: 100%;
      margin-bottom: 20px;
    }
  };
  background-color: $bgGray;
  margin: 46px auto 0;

  &-item{
    float: right;

    &:hover{
      opacity: .7;
    }

    &:first-child{
      float: left;
    }
  }
}
