input.form-control {
  &[type="text"] {
    display:block;
    width: 100%;
    padding: 16px 6px;
    border-radius: 4px;
    background: #fff;
    border: 1px solid #ccc;
  }
  &.form-inline {
    display: inline;
    width: auto;
  }
}

textarea.form-control {
  display:block;
  width: 100%;
  padding: 16px 6px;
  border-radius: 4px;
  background: #fff;
  border: 1px solid #ccc;

}
