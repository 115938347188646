
// カラー
$baseColor   : #007cff;
$keyColor    : #4dffcd;
$accentColor : #ffce4b;
$textColor   : #333c4e;
$bgBlue      : #281cb6;
$bgGray      : #f9f9f9;
$bgDarkGray  : #6A6B75;
$ltGray      : #f4f4f4;
$maxWidth    : 1160px; //最大幅
$maxLowerWidth    : 1024px; // 下層の最大幅

$breakpoint-tablet: 980px; // タブレットのブレイクポイント
$breakpoint-mobile: 740px; // スマートフォンのブレイクポイント
