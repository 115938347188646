
.content {

  @at-root {
    &-bg-gray {
      margin-top: 40px;
      padding-top: 40px;
      margin-bottom: 0;
      padding-bottom: 40px;
      background-color: $bgGray;
    }
  }

  margin-bottom: 80px;

  @include ltMaxWidth {
    width: $maxWidth;
  };
  @include mobile-width {
    width: auto;
  };

  // セクション
  &Section {

    margin-bottom: 80px;

    // Clearfix
    &:after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      opacity: 0;
      clear: both;
    }

    &-inner {
      @include widthControl('lower');
      margin: auto;
      &-left {
        float: left;
      }
      &-right {
        float: right;
      }
      @include mobile-width {
        margin: auto 20px;
        img {
          width: 100%;
          height: auto;
          &.columnIcon {
            width: auto;
          }
        }
      };

      .row.opposite {
        [class^="col-"] {
          float: right;
        }
      }
    }

    // タイトル
    &-title {
      font-size: 28px;
      @include mobile-width {
        font-size: 24px;
        line-height: 1.5;
      };
      &-blue {
        color: $baseColor;
      }
    }

    &-lead {
      font-size: 40px;
      margin-top: 30px;
      font-weight: bold;
      margin-bottom: 50px;
      @include mobile-width {
        font-size: 28px;
        line-height: 1.5;
        margin: 20px auto 30px;
      };
    }

    &-wrap {
      margin: 50px 0;
      @include content-default-style;
    }

    // ボーダー有りのセクション
    &-withBorder {
      padding-top: 30px;
      position: relative;
      & .contentSection-title {
        font-size: 40px;
        height: 150px;
        @include mobile-width {
          height: 100px;
          font-size: 24px;
        };
      }
      & .image-wrap {
        img {
          margin-top: 40px;
          @include mobile-width {
            margin-top: auto;
          };
        }
      }
      & .contentSection-wrap {
        margin-top: 60px;
      }
      &:before {
        content: "";
        display: block;
        background: $bgGray;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 200px;
        @include mobile-width {
          height: 150px;
        };
      }
    }

    &-bgImage {
      padding: 28px 0;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      & .contentSection-wrap {
        margin-bottom: 0;
      }
    }
  }

}
