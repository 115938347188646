.topAccess {
    .topContent--access {
        padding-bottom: 0;
        background-color: #fff;
    }
    .access-inner{
        padding-top: 30px;
    }
    .access-contents {
        @include widthControl("");
    }
}
