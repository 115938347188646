.content-sitemap {
  .content-inner {
    display: flex;
    @include widthControl("lower");
    margin: auto;
    @include mobile-width {
      display: block;
    };
  }
  .sitemap {
    @at-root {
      &-nav {
        width: 25%;
        margin-right: 73px;
        @include mobile-width {
          width: auto;
          margin: 10px;
          margin-bottom: 30px;
        };

        &-recruit {
          margin-bottom: 60px;
        }

        .contentSection-title {
          a {
            color: $baseColor;
          }
          color: $baseColor;
          margin-bottom: 14px;
          text-decoration: none;
          font-size: 16px;
          border-bottom: 1px solid $baseColor;
        }

        .contentSection-wrap {
          margin: 14px 0;
        }

        ul {
          padding: 0;
          margin: 0;
        }
        li {
          list-style: none!important;
          a {
            color: $textColor;
            &:hover {
              text-decoration: underline;
            }
          }
          & > ul {
            margin-bottom: 30px;
            li {
              margin-left: 10px;
              a:before {
                content: "-";
              }
            }
          }
        }
      }
    }
  }
}
