.divisions {

  .col3-item{
    margin-top: 80px;
    @include mobile-width() {
      width: auto;
      margin: 0 0 10px;
      background: #fff;
      border: 1px solid #ccc;
      padding: 30px 0;
      img {
        display: inline-block!important;
      }
    };
    position: relative;
    &-icon{
      text-align: center;
    }
    &-title{
      font-size: 1.429rem;
      margin-top: 30px;
      text-align: center;
    }
    &-des{
      width: 90%;
      min-height: 84px;
      margin: 24px auto 0;
    }
  }
  .btn-default{
    margin-top: 12px;
    text-align: center;
    position: relative;
  }
  .col3-footer {
    text-align: center;
    @include mobile-width {
      padding: 0 10px;
    };
  }
}
