.mainVisual-under.news {
	background: url(../img/news/kv.jpg) no-repeat center 0 / cover;
}

.lower.lower--nav-color-gray.news-single {
	padding-top: 85px;
}

.content-news-single {
	.contentSection {
		@at-root {
			&-title {
				text-align: center;
				font-size: 26px;

				@include mobile-width {
					text-align: left;
					font-size: 20px;
				}
			}

			&-lead {
				text-align: center;
				font-size: 16px;

				@include mobile-width {
					text-align: left;
					font-size: 13px;
				}
			}

			&-meta {
				position: relative;
				overflow: hidden;
				margin: 20px 0;

				.date {
					float: left;
				}

				.sns-feed {
					float: right;

					& > div {
						display: inline-block;
						vertical-align: top;
					}
				}
			}

			&-wrap {
				h2 {
					margin: 60px 0 25px;
				}

				h3 {
					margin: 60px 0 25px;
					font-size: 20px;
					text-align: center;
				}

				hr {
					border-top: 1px solid #DCDCDC;
					margin: 30px 0 30px;
				}

				table {
					@extend .table;
					border-left: 1px solid #ccc;
					border-right: 1px solid #ccc;

					td {
						padding: 15px;
						font-size: 14px;
						vertical-align: middle;
					}

					th {
						padding: 15px;
						background: #f2f2f2;
						vertical-align: middle;
					}
				}
			}
		}
	}

}


.news-list {
	width: $maxLowerWidth;

	@include ltMaxWidth {
		width: auto;
	}

	@include mobile-width() {
		width: auto;
	}

	margin: auto;
	margin-bottom: 50px;
	overflow: hidden;

	a:hover {
		text-decoration: none;
	}

	@at-root {
		&-wrap {
			overflow: hidden;
			width: $maxLowerWidth;

			@include mobile-width() {
				width: auto;
			}

			margin: auto;
		}

		&--left {
			width: 48%;
			float: left;
		}

		&--right {
			width: 48%;
			float: right;
		}

		&-item {
			overflow: hidden;
			display: table;
			width: 100%;
			border-top: 1px solid #ccc;
		}

		a:last-child &-item {
			border-bottom: 1px solid #ccc;
		}

		&-category {
			display: table-cell;
			padding: 10px;
			width: 120px;
			padding-right: 30px;
			height: 110px;
			text-align: center;
			vertical-align: middle;
			.news-list-item-category {
				background-color: #4a99ed;
				color: #fff;
				text-align: center;
				padding: 4px;
				font-size: 12px;
				font-weight: bold;
			}
			@include mobile-width {
				width: 100px;
				padding-right: 5px;
				height: 85px;
				.news-list-item-category {
					font-size: 11px;
				}
			}
		}

		&-content {
			padding: 10px;
			display: table-cell;
			vertical-align: middle;
			font-size: 16px;
			color: $textColor;
		}

		&-date {
			margin: 0 0 10px !important;

			a:hover & {
				text-decoration: none;
			}
		}

		a:hover &-title {
			text-decoration: underline;
			color: $baseColor;
		}

		&-title {
			font-size: 20px;

			@include mobile-width {
				font-size: 16px;
			}

			;
		}
	}
}

.news-year-list {
	margin: auto;
	padding: 36px;
	background: #f9f9f9;

	@include mobile-width {
		padding: 10px;
	}

	;

	@at-root {
		&-header {
			margin-bottom: 50px;
		}

		&-items {
			overflow: hidden;
		}

		&-item {
			list-style: none !important;
			$list-item-width: 100%;
			float: left;
			margin-bottom: 30px;
			font-size: 18px;
			width: $list-item-width / 7;

			@include mobile-width {
				width: $list-item-width /2;
			}

			;

			&:before {
				content: '\f054';
				font-family: 'FontAwesome';
				font-size: 15px;
				margin-right: 10px;
				color: $baseColor;
			}

			a {
				color: $textColor;
			}
		}
	}
}
